import { Flex, Icon, Center, Text } from '@chakra-ui/react';
import { AiOutlineCheckCircle } from 'react-icons/ai';

export const StepsCompleted = ({ back }) => (
    <>
        <Center>
            <Flex textAlign="center" alignItems="center" direction="column">
                <Icon color="resolveGreen" as={AiOutlineCheckCircle} w={25} h={25} />
                <Text color="resolveGreen" fontWeight="bold" fontSize="xl">
                    Item Ordered
                </Text>
            </Flex>
        </Center>
    </>
);