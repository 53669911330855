import { useMemo, useCallback } from 'react';
import {
    Box,
    Stepper,
    StepTitle,
    StepDescription,
    StepIndicator,
    StepIcon,
    StepSeparator,
    StepStatus,
    StepNumber,
    Step
} from '@chakra-ui/react';
import { useParams, useLocation, useNavigate } from 'react-router';
import { STEPS } from '../constants';
import { getStepIndexFromPath } from '../utils';

export const OrderStepper = ({ isCompleted, setIsCompleted }) => {
    const { quoteId, itemId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const activeIndex = useMemo(() => 
        isCompleted 
            ? getStepIndexFromPath(location.pathname) + 1 
            : getStepIndexFromPath(location.pathname),
        [location.pathname, isCompleted]
    );

    const handleStepClick = useCallback((path) => {
        setIsCompleted(false);
        navigate(`/quotes/${quoteId}/order/${itemId}/${path}`);
    }, [setIsCompleted, navigate, quoteId, itemId]);

    const steps = useMemo(() => STEPS, []); // Memoize steps array since it never changes

    const stepperContent = useMemo(() => (
        steps.map((step, index) => (
            <Step key={index} style={{ cursor: 'pointer' }} onClick={() => handleStepClick(step.path)}>
                <StepIndicator>
                    <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                    />
                </StepIndicator>

                <Box flexShrink="0">
                    <StepTitle>{step.label}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                </Box>

                <StepSeparator />
            </Step>
        ))
    ), [handleStepClick, steps]); // Include steps in dependency array

    return (
        <Stepper mb="1rem" colorScheme="resolveOranger" index={activeIndex}>
            {stepperContent}
        </Stepper>
    );
};
