import { FormControl, FormHelperText, Button } from "@chakra-ui/react"
export const NoMatchingCosts = ({ isLoading, handleClearCharge }) => {
    return (
            <FormControl mt="1rem">
                <FormHelperText>
                    Item has an associated charge, but it wasn't found on the
                    ticket. Click the below button to clear the Charge from the
                    Item and then add the Charge again
                </FormHelperText>
                <Button
                    variant="outline"
                    mt="1rem"
                    color="resolveOrange"
                    isLoading={isLoading}
                    onClick={handleClearCharge}
                >
                    Clear Cost
                </Button>
            </FormControl>
    )
}