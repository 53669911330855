import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getTicketContact = async ({ queryKey }) => {
  const [,ticketNumber] = queryKey
  const { data } = await axiosClient({
    url: `/autotask/ticket/contact`,
    method: 'POST',
    data: { ticketNumber }
  })
  return data
}

export const useGetTicketContact = ({ ticketNumber, enabled, onSuccess }) => {
  return useQuery(['autotask-ticket-contact', ticketNumber], {
    queryFn: getTicketContact,
    onSuccess,
    enabled,
    retry: false 
 })
}


