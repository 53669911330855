import { FormControl, FormLabel, Textarea, FormErrorMessage } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

export const TicketDescriptionInput = ({
    name,
    required = false,
    showLabel = true,
    rules = {},
    ...props
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <FormControl
            isInvalid={!!errors[name]}
            isRequired={required}
            {...props}
        >
            {showLabel && <FormLabel>Ticket Description</FormLabel>}
            <Textarea
                {...register(name, { required, ...rules })}
                id="ticket_description"
            />
            <FormErrorMessage>
                {errors[name] && errors[name].message}
            </FormErrorMessage>
        </FormControl>
    );
};