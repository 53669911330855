import { useResolveToast } from 'hooks/useResolveToast';
import { axiosClient } from 'lib/axiosClient';
import { useMutation, useQueryClient } from 'react-query';

const createAutotaskTicket = async ({ ticket }) => {
    const { data: ticketRes } = await axiosClient({
        url: '/autotask/ticket',
        method: 'post',
        data: ticket,
    });

    return ticketRes;
};

export const useCreateAutotaskTicket = ({ quoteId, itemId, onSuccess }) => {
    const queryClient = useQueryClient();
    const { toastSuccess, toastFail } = useResolveToast();
    return useMutation(createAutotaskTicket, {
        onSuccess: ticket => {
            toastSuccess('Ticket created successfully');
            queryClient.invalidateQueries(['quote-items']);
            queryClient.invalidateQueries(['item-status']);
            onSuccess(ticket);
        },
        onError: () => {
            toastFail('Failed to create ticket');
        },
    });
};
