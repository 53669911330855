import { useFormContext } from "react-hook-form";
import { FormControl, FormHelperText, Textarea } from "@chakra-ui/react";

export const NotesInput = ({ name, required = false }) => {
    const { register } = useFormContext();
    return (
        <FormControl mt="1rem">
            <FormHelperText>
                Add any additional notes or instructions
            </FormHelperText>
            <Textarea {...register(name, { required })} id="notes" />
        </FormControl>
    );
};