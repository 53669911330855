import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useGetItemBillingDetails } from '../api/getItemBillingDetails';
import { ORDER_FORM_DEFAULT_VALUES } from '../constants';

export const useOrderForm = (itemId) => {
    const methods = useForm({
        defaultValues: {
            ...ORDER_FORM_DEFAULT_VALUES,
            item_id: itemId,
        },
    });

    const itemBillingDetails = useGetItemBillingDetails({
        itemId,
        onSuccess: data => {
            methods.setValue('at_billing_code_id', data.at_billing_code_id);
            methods.setValue('autotask_number', data.autotask_number);
            methods.setValue('client_id', data.client_id);
            methods.setValue('date_ordered', data.date_ordered);
            methods.setValue('supplier_id', data.supplier_id);
            methods.setValue('item_ref', data.item_ref);
            methods.setValue('quote_id', data.quote_id);
            methods.setValue('at_cost_id', data.at_cost_id);
            methods.setValue('client_pon', data.client_pon);
            methods.setValue(
                'supplier_order_number',
                data.supplier_order_number
            );
            methods.setValue('payment_method_id', data.payment_method_id);
        },
    });

    const handleSave = useCallback(async (saveFunction) => {
        const isValid = await methods.trigger();
        if (!isValid) return false;

        try {
            await saveFunction();
            return true;
        } catch (error) {
            console.error('Form submission failed:', error);
            return false;
        }
    }, [methods]);

    return {
        methods,
        handleSave,
        isLoading: itemBillingDetails.isLoading || itemBillingDetails.isFetching
    };
};
