import { useFormContext, Controller } from 'react-hook-form';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from '@chakra-ui/react';

export const EstimatedHoursInput = ({
    name,
    required = true,
    selectedOptionColor = 'resOrange',
    ...props
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            rules={{
                required,
                min: { value: 0, message: 'Must include estimated hours' },
            }}
            render={({ field: { ref, ...restField } }) => {
                return (
                    <FormControl
                        isInvalid={!!errors[name]}
                        isRequired={required}
                        {...props}
                    >
                        <FormLabel>Estimated Hours</FormLabel>
                        <NumberInput
                            flexGrow={1}
                            min={0}
                            step={1}
                            defaultValue={0}
                            {...restField}
                        >
                            <NumberInputField
                                flexGrow={1}
                                ref={ref}
                                name={restField.name}
                            />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage>
                            {errors[name] && errors[name].message}
                        </FormErrorMessage>
                    </FormControl>
                );
            }}
        />
    );
};
