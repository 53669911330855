import { axiosClient } from "lib/axiosClient"
import { useQuery } from "react-query"

export const getAutotaskClientContacts = async (context) => {
  const { signal, queryKey } = context
  const [_, clientId] = queryKey
  const { data: contacts } = await axiosClient({
    url: `/autotask/contacts/${clientId}`,
    signal
  })
  console.log(`got contacts for ${clientId}`)
  return contacts
}

export const useGetAutotaskClientContacts = ({ clientId, enabled }) => {
  return useQuery(['autotask-client-contacts', clientId], {
    queryFn: getAutotaskClientContacts,
    placeholderData: [],
    staleTime: Infinity,
    enabled 
  })
}