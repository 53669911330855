import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"

export const updateExistingTicket = async ({ itemId, autotaskNumber, contactId }) => {
    await axiosClient({
        url: `/quotes/order/note`,
        method: 'post',
        data: {
            itemId,
            autotaskNumber,
            contactId
        }
    })
}

export const useUpdateExistingTicket = () => {
  const queryClient = useQueryClient()
  const { toastSuccess, toastFail } = useResolveToast()
  return useMutation(updateExistingTicket, {
    onSuccess: () => {
      toastSuccess('Ticket note added successfully')
      queryClient.invalidateQueries(['quote-items'])
      queryClient.invalidateQueries(['item-billing-details'])
    },
    onError: () => {
      toastFail('Failed to add note to ticket')
    }
  })
}
