import { Flex, Button, FormControl, FormLabel } from "@chakra-ui/react"
export const NoCostId = ({ isLoading, handleAddCost }) => {
    return (
        <FormControl mt="1rem">
            <FormLabel>Autotask Cost</FormLabel>
            <Flex flexDir="column">
                <Flex>
                    <Button
                        variant="outline"
                        color="resolveOrange"
                        isLoading={isLoading}
                        onClick={handleAddCost}
                    >
                        Add Cost
                    </Button>
                </Flex>
            </Flex>
        </FormControl>
    );
};
