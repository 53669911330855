import { useResolveToast } from 'hooks/useResolveToast';
import { axiosClient } from 'lib/axiosClient';
import { useMutation, useQueryClient } from 'react-query';

export const updateTicketClient = async ({
    itemId,
    autotaskNumber,
    quoteId,
    clientId,
}) => {
    await axiosClient({
        url: `/autotask/ticket/client`,
        method: 'post',
        data: {
            itemId,
            autotaskNumber,
            clientId: clientId?.value,
            quoteId
        },
    });
};

export const useUpdateTicketClient = () => {
    const queryClient = useQueryClient();
    const { toastSuccess, toastFail } = useResolveToast();
    return useMutation(updateTicketClient, {
        onSuccess: () => {
            toastSuccess('Ticket client changed successfully');
            queryClient.invalidateQueries(['quote-items']);
            queryClient.invalidateQueries(['item-billing-details']);
            queryClient.invalidateQueries(['autotask-ticket-contact']);
        },
        onError: () => {
            toastFail('Failed to add note to ticket');
        },
    });
};
