import { Flex, VStack, Text, Center, Icon } from '@chakra-ui/react'
import { FaHeartBroken } from 'react-icons/fa'

const SomethingBroke = ({ message = 'Something broke', error='', h="100vh"}) => {

  return (
    <Flex h={h} justifyContent='center' alignItems='center'>
      <Center>
        <VStack>
          <Icon color='resolveOrange' as={FaHeartBroken} w={55} h={55} />
          <Text>{message}</Text>
          {
            error && (
              <Text fontSize='xs'>{error}</Text>
            )
          }
        </VStack>
      </Center>
    </Flex>
  )
}

export default SomethingBroke
