// 1. Import `extendTheme`
import { extendTheme } from '@chakra-ui/react'
import { StepsTheme as Steps } from 'chakra-ui-steps'

// 2. Call `extendTheme` and pass your custom values
const customTheme = extendTheme({
  config: {
    initialColorMode: 'light'
  },
  colors: {
    resolveOrange: '#F26B21',
    resolveOranger: {
      50: '#f26b211f',
      100: '#F26B21',
      200: '#F26B21',
      300: '#F26B21',
      400: '#F26B214f',
      500: '#F26B21',
      600: '#F26B21',
      700: '#F26B21',
      800: '#F26B21',
      900: '#F26B21'
    },
    resolveYellow: '#F2D321',
    resolveBluer: {
      50: '#00a7e01f',
      100: '#00A7E0',
      200: '#00A7E0',
      300: '#00A7E0',
      400: '#00A7E0',
      500: '#00A7E0',
      600: '#00A7E0',
      700: '#00A7E0',
      800: '#00A7E0',
      900: '#00A7E0'
    },
    resolveLightGrey: '#D0D2D3',
    resolveDarkGrey: '#8F9194',
    resolveGreen: '#3EBFAC',
    resolveGreener: {
      50: '#3ebfac1f',
      100: '#3EBFAC',
      200: '#3EBFAC',
      300: '#3EBFAC',
      400: '#3EBFAC',
      500: '#3EBFAC',
      600: '#3EBFAC',
      700: '#3EBFAC',
      800: '#3EBFAC',
      900: '#3EBFAC'
    },
    resolveBlue: '#00A7E0',
    resolvePink: '#E7417A',
    resolvePinker: {
      50: '#E7417A',
      100: '#E7417A',
      200: '#E7417A',
      300: '#E7417A',
      400: '#E7417A',
      500: '#E7417A',
      600: '#E7417A',
      700: '#E7417A',
      800: '#E7417A',
      900: '#E7417A'
    },
    resolvePurple: '#9E1A96',
    resolvePurpler: {
      50: '#9E1A96',
      100: '#9E1A96',
      200: '#9E1A96',
      300: '#9E1A96',
      400: '#9E1A96',
      500: '#9E1A96',
      600: '#9E1A96',
      700: '#9E1A96',
      800: '#9E1A96',
      900: '#9E1A96'
    },
    resolveRed: '#F59794',
    resolveReder: {
      50: '#F597941F',
      100: '#f59794',
      200: '#f59494',
      300: '#f59494',
      400: '#f59494',
      500: '#f59494',
      600: '#f59494',
      700: '#f59494',
      800: '#f59494',
      900: '#f59494'
    },
  },
  fonts: {
    body: 'GTWalsheimLight',
    heading: 'GTWalsheim',
    mono: 'Menlo, monospace'
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '64px'
  },
  fontWeights: {
    normal: 200,
    medium: 500,
    bold: 800
  },
  components: {
    Steps
  }
})

export default customTheme

/**
 *
 *  PRIMARY ORANGE: #F26B21
 *  PRIMARY GREY L: #D0D2D3
 *  PRIMRAY GREY D: #8F9194
 *
 *  SECONDARY
 */
