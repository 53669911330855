import { FormControl, FormLabel, Flex, Link, Tag, Spacer, Button } from "@chakra-ui/react"
import { useWatch } from "react-hook-form";

import { useDeleteCost } from "features/Quotes/api/deleteCost";
import { useParams } from "react-router";

export const Costs = ({ costs }) => {

    const { itemId } = useParams();
    const itemRef = useWatch({ name: 'item_ref' });

    const deleteCost = useDeleteCost();
    const handleDeleteCost = async e => {
        const selectedCost = costs.data.find(
            c => c.id === parseInt(e.target.id)
        );
        if (selectedCost) {
            await deleteCost.mutateAsync({ selectedCost, itemId });
        }
    };

    return (
        <FormControl mt="1rem">
            <FormLabel>Autotask Cost</FormLabel>
            {costs
                .filter(c => c.internalPurchaseOrderNumber === itemRef)
                .map(cost => (
                    <Flex key={cost.id}>
                        <Tag color="green">
                            <Link
                                target="_blank"
                                rel="noreferrer"
                                href={`https://ww4.autotask.net/Autotask/views/ServiceDesk/ServiceDeskTicket/service_ticket_panel_edit.aspx?ticketRenderType=Cost&GenericId=${cost.id}&ticketID=${cost.ticketID}`}
                            >
                                {cost.name}
                            </Link>
                        </Tag>
                        <Spacer />
                        <Button
                            size="xs"
                            color="resolveRed"
                            variant="ghost"
                            id={cost.id}
                            isLoading={deleteCost.isLoading}
                            onClick={handleDeleteCost}
                        >
                            Delete
                        </Button>
                    </Flex>
                ))}
        </FormControl>
    )
}