import { useFormContext } from "react-hook-form";
import { FormControl, FormLabel, Textarea, FormErrorMessage } from "@chakra-ui/react";

export const DeliveryAddressInput = ({
    name,
    required,
    showLabel = true,
    rules,
    ...props
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <FormControl
            isInvalid={!!errors[name]}
            isRequired={required}
            {...props}
        >
            {showLabel && <FormLabel>Hardware Delivery Address</FormLabel>}
            <Textarea {...register(name, { required, ...rules })} id="name" />
            <FormErrorMessage>
                {errors[name] && errors[name].message}
            </FormErrorMessage>
        </FormControl>
    );
};