import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useFormContext } from 'react-hook-form';
import {
    ButtonGroup,
    IconButton,
    Button,
    PopoverTrigger,
    PopoverCloseButton,
    useDisclosure,
    Popover,
    PopoverContent,
    PopoverFooter,
    PopoverBody,
    PopoverHeader,
} from '@chakra-ui/react';
import {
    IoMdRefresh,
    IoMdArrowDropright,
    IoMdArrowDropleft,
} from 'react-icons/io';
import { getNavigationLinks } from '../utils';

const UnsavedChangesPopover = ({ isOpen, onClose, onDiscard, onSaveAndContinue }) => (
    <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader fontWeight="bold">
            Unsaved changes
        </PopoverHeader>
        <PopoverBody>
            You haven't saved your changes, are you sure you want to continue?
        </PopoverBody>
        <PopoverFooter display="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
                <Button variant="outline" colorScheme="red" onClick={onDiscard}>
                    Discard Changes
                </Button>
                <Button colorScheme="resolveGreener" onClick={onSaveAndContinue}>
                    Save & Continue
                </Button>
            </ButtonGroup>
        </PopoverFooter>
    </PopoverContent>
);

export const StepperActions = ({ isCompleted, setIsCompleted, handleSave }) => {
    const { quoteId, itemId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { isOpen, onToggle, onClose } = useDisclosure();
    
    const orderForm = useFormContext();
    const isDirty = orderForm.formState.isDirty;

    const { prevStep, nextStep } = useMemo(() => 
        getNavigationLinks(location.pathname),
        [location.pathname]
    );

    const navigateItem = useCallback(() => {
        navigate(`/quotes/${quoteId}/edit/${itemId}`);
    }, [navigate, quoteId, itemId]);

    const handleBack = useCallback(() => {
        if (prevStep) {
            setIsCompleted(false);
            navigate(`/quotes/${quoteId}/order/${itemId}/${prevStep}`);
        }
    }, [prevStep, setIsCompleted, navigate, quoteId, itemId]);

    const handleNext = useCallback(async () => {
        if (!(await orderForm.trigger())) {
            return;
        }

        if (isDirty) {
            onToggle();
            return;
        }

        if (nextStep) {
            navigate(`/quotes/${quoteId}/order/${itemId}/${nextStep}`);
        } else {
            setIsCompleted(true);
        }
    }, [isDirty, navigate, onToggle, orderForm, itemId, quoteId, nextStep, setIsCompleted]);

    const handleDiscard = useCallback(() => {
        onToggle();
        orderForm.reset({}, { keepValues: true });
        
        if (nextStep) {
            navigate(`/quotes/${quoteId}/order/${itemId}/${nextStep}`);
        } else {
            setIsCompleted(true);
        }
    }, [navigate, onToggle, orderForm, nextStep, quoteId, itemId, setIsCompleted]);

    const handleSaveAndContinue = useCallback(async () => {
        const isValid = await orderForm.trigger();
        if (!isValid) return;

        try {
            await handleSave();
            onClose();
            if (nextStep) {
                navigate(`/quotes/${quoteId}/order/${itemId}/${nextStep}`);
            } else {
                setIsCompleted(true);
            }
        } catch (error) {
            console.error('Form submission failed:', error);
        }
    }, [handleSave, onClose, navigate, nextStep, quoteId, itemId, setIsCompleted, orderForm]);

    return (
        <ButtonGroup width="100%" mt="2rem" justifyContent="space-between">
            <ButtonGroup>
                <IconButton
                    colorScheme="resolveOranger"
                    icon={<IoMdRefresh />}
                    onClick={() => orderForm.reset()}
                    aria-label="Reset form"
                    color="white"
                />
            </ButtonGroup>
            <ButtonGroup>
                {prevStep && (
                    <IconButton
                        colorScheme="resolveOranger"
                        color="white"
                        icon={<IoMdArrowDropleft />}
                        onClick={handleBack}
                        aria-label="Previous step"
                    />
                )}
                {isCompleted ? (
                    <Button
                        onClick={navigateItem}
                        color="white"
                        colorScheme="resolveOranger"
                    >
                        Back to Item
                    </Button>
                ) : (
                    <Popover
                        returnFocusOnClose={false}
                        isOpen={isOpen}
                        onClose={onClose}
                        placement="right"
                        closeOnBlur={false}
                    >
                        <PopoverTrigger>
                            <IconButton
                                colorScheme="resolveOranger"
                                onClick={handleNext}
                                color="white"
                                icon={<IoMdArrowDropright />}
                                aria-label="Next step"
                            />
                        </PopoverTrigger>
                        <UnsavedChangesPopover
                            isOpen={isOpen}
                            onClose={onClose}
                            onDiscard={handleDiscard}
                            onSaveAndContinue={handleSaveAndContinue}
                        />
                    </Popover>
                )}
            </ButtonGroup>
        </ButtonGroup>
    );
};
