

import { useResolveToast } from "hooks/useResolveToast"
import { axiosClient } from "lib/axiosClient"
import { useMutation, useQueryClient } from "react-query"

export const updateItemTicket = async ({itemId, form }) => {
    console.log(form)
    const { autotask_number } = form
    await axiosClient({
        url: `/quotes/item/ticket/${itemId}`,
        method: 'patch', 
        data: { autotask_number }
    })
}

export const useUpdateItemTicket = () => {
  const queryClient = useQueryClient()
  const { toastSuccess, toastFail } = useResolveToast()
  return useMutation(updateItemTicket, {
    onSuccess: () => {
      toastSuccess('Ticket saved successfully')
      queryClient.invalidateQueries(['quote-items'])
    },
    onError: () => {
      toastFail('Failed to update item ticket number')
    }
  })
}
