import { STEPS } from './constants';

export const getStepIndexFromPath = (pathname) => {
    const currentPath = pathname.split('/').pop();
    const next = STEPS.findIndex(step => step.path === currentPath);
    return next
};

export const getNavigationLinks = (currentPath) => {
    const currentStep = STEPS.findIndex(step => currentPath.includes(step.path));
    const prevStep = currentStep > 0 ? STEPS[currentStep - 1].path : null;
    const nextStep = currentStep < STEPS.length - 1 ? STEPS[currentStep + 1].path : null;
    return { prevStep, nextStep };
};

export const isValidAutotaskNumber = number => {
    return /^(T)[0-9]{8}.[0-9]{4}/g.test(number);
};


