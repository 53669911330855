import { FormControl, FormLabel } from "@chakra-ui/react";
import { ControlledSelect } from "components/Form/ControlledSelect";

export const AutotaskTicketContactUpdate = ({ atClientContacts }) => {
    return (
        <FormControl mt="1rem">
            <FormLabel>Ticket Contact</FormLabel>
            <ControlledSelect
                name="at_client_contact_id"
                rules={{ required: false }}
                isClearable
                isLoading={
                    atClientContacts.isLoading || atClientContacts.isFetching
                }
                options={atClientContacts.data}
                selectedOptionColor="resolveOranger"
            />
        </FormControl>
    );
};

