import { useFormContext } from "react-hook-form";
import { FormControl, FormLabel, FormHelperText, Textarea, FormErrorMessage } from "@chakra-ui/react";

export const ServicesDetailInput = ({
    name,
    required = true,
    selectedOptionColor = 'resOrange',
    ...props
}) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <FormControl
            isInvalid={!!errors[name]}
            isRequired={required}
            {...props}
            mt="1rem"
        >
            <FormLabel mb={0}>Hardware, Software & Services</FormLabel>
            <FormHelperText mt={0}>
                List all Hardware, Software, and Services the client has agreed
                to
            </FormHelperText>
            <Textarea {...register(name, { required: true })} id="services" />
            <FormErrorMessage>
                {errors[name] && errors[name].message}
            </FormErrorMessage>
        </FormControl>
    );
};