import { useParams, useLocation, useNavigate } from 'react-router';
import { useContext, useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { UserContext } from 'features/Auth/context/user.context';
import { useGetAutotaskClientContacts } from 'features/Quotes/api/getAutotaskClientContacts';
import { useCreateAutotaskTicket } from 'features/Quotes/api/createAutotaskTicket';
import {
    FormControl,
    FormLabel,
    Heading,
    Input,
    Button,
    ButtonGroup,
} from '@chakra-ui/react';
import { ControlledSelect } from 'components/Form/ControlledSelect';
import { getNavigationLinks } from '../utils';
import { Loading } from 'components/Loading';

export const NewAutotaskTicket = () => {
    const { quoteId, itemId } = useParams();
    const { engineer } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    const orderForm = useFormContext();
    const contactId = orderForm.watch('at_client_contact_id');
    const clientId = useWatch({ name: 'client_id' });

    const [ticketTitle, setTicketTitle] = useState(null);

    const atClientContacts = useGetAutotaskClientContacts({
        clientId: clientId?.value,
        enabled: !!ticketTitle,
    });

    const createAutotaskTicket = useCreateAutotaskTicket({
        quoteId,
        itemId,
        contactId,
        onSuccess: ticket => {
            orderForm.setValue('autotask_number', ticket.ticketNumber);
            setTicketTitle(null);
        },
    });

    const handleSave = useCallback(async () => {
        await createAutotaskTicket.mutateAsync({
            ticket: {
                contactId: contactId?.value || null,
                title: ticketTitle,
                client_id: clientId?.value || null,
                item_id: itemId,
                quote_id: quoteId,
                engineer: {
                    engineer_id: engineer.quotes.id,
                    autotask_id: engineer.quotes.autotask_id,
                },
            },
        });

        const nextStep = getNavigationLinks(location.pathname).nextStep;
        navigate(`/quotes/${quoteId}/order/${itemId}/${nextStep}`);
    }, [
        createAutotaskTicket,
        ticketTitle,
        engineer,
        itemId,
        clientId,
        quoteId,
        contactId,
        location.pathname,
        navigate,
    ]);

    useState(() => {
        if (!ticketTitle && clientId?.label) {
            setTicketTitle(`Q${quoteId} - Sale for ${clientId.label}`);
        }
    }, [clientId?.label]);

    return (
        <>

            <Heading size="md" mt="1rem" color="resolveOrange">New Ticket</Heading>
            <FormControl mt="1rem">
                <FormLabel>Ticket Title</FormLabel>
                <Input
                    mr="1rem"
                    value={ticketTitle || ''}
                    onChange={e => setTicketTitle(e.target.value)}
                />
            </FormControl>
            <FormControl mt="1rem">
                <FormLabel>Ticket Contact</FormLabel>
                {atClientContacts.isLoading || atClientContacts.isFetching ? (
                    <Loading />
                ) : (
                    <ControlledSelect
                        name="at_client_contact_id"
                        rules={{ required: false }}
                        isLoading={atClientContacts.isLoading}
                        options={atClientContacts.data}
                        selectedOptionColor="resolveOranger"
                    />
                )}
            </FormControl>
            <ButtonGroup mt="1rem">
                <Button color="white" bg="resolveOrange" onClick={handleSave} isLoading={createAutotaskTicket.isLoading}>
                    Create Ticket
                </Button>
            </ButtonGroup>
        </>
    );
};
