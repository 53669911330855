import { useMemo, useEffect, useContext, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { Button } from '@chakra-ui/react';

import { useUpdateItemBillingDetails } from '../api/updateItemBillingDetails';

import { ClientPOInput } from 'components/Form/ClientPOInput';
import { PaymentMethodSelectInput } from 'components/Form/PaymentMethodSelectInput';
import { SupplierOrderNumberSelectInput } from 'components/Form/SupplierOrderNumberSelectInput';
import { BillingCodeSelect } from 'components/Form/BillingCodeSelect';

import { UserContext } from 'features/Auth/context/user.context';
import { OrderContext } from '..';

import { getNavigationLinks } from '../utils';

export const BillingDetailsForm = () => {

    const { quoteId, itemId } = useParams();

    const { engineer } = useContext(UserContext);
    const { setStepSave } = useContext(OrderContext);

    const orderForm = useFormContext();
    
    const navigate = useNavigate();
    const location = useLocation();

    const updateItemBillingDetails = useUpdateItemBillingDetails();
 
    const { nextStep } = useMemo(() => {
        return getNavigationLinks(location.pathname);
    }, [location.pathname]);

    const handleSave = useCallback(async () => {
        if (!orderForm.trigger()) {
            return;
        }

        const form = orderForm.getValues();
        await updateItemBillingDetails.mutateAsync({
            itemId,
            form,
            engineerId: engineer.quotes.id,
        });

        orderForm.reset({}, { keepValues: true });
        navigate(`/quotes/${quoteId}/order/${itemId}/${nextStep}`);
    }, [
        engineer,
        itemId,
        orderForm,
        updateItemBillingDetails,
        nextStep,
        navigate,
        quoteId,
    ]);

    useEffect(() => {
        setStepSave(() => handleSave);
        return () => setStepSave(null);
    }, [setStepSave, handleSave]);

    return (
        <form onSubmit={orderForm.handleSubmit(handleSave)}>
            <BillingCodeSelect
                name="at_billing_code_id"
                control={orderForm.control}
                errors={orderForm.formState.errors}
                labelText="Billing Code"
                required
                mt="1rem"
            />
            <PaymentMethodSelectInput
                control={orderForm.control}
                name="payment_method_id"
                errors={orderForm.formState.errors}
                required={true}
                mt="1rem"
            />
            <ClientPOInput
                register={orderForm.register}
                name="client_pon"
                errors={orderForm.formState.errors}
                required={false}
                mt="1rem"
            />
            <SupplierOrderNumberSelectInput
                register={orderForm.register}
                name="supplier_order_number"
                errors={orderForm.formState.errors}
                required={false}
                mt="1rem"
            />

            <Button
                color="white"
                mt="1rem"
                colorScheme="resolveOranger"
                type="submit"
            >
                Save
            </Button>
        </form>
    );
};
