import { FormControl, FormLabel } from '@chakra-ui/react'
import { ControlledSelect } from './ControlledSelect'
import { useQuery } from 'react-query'
import { axiosClient } from 'lib/axiosClient'

const getBuildTiers = async (context) => {
  const { signal } = context
  const { data } = await axiosClient({
    url: '/settings/build-tiers',
    signal
  })
  return data 
}

const useGetBuildTiers = () => {
  return useQuery(['form-build-tiers'], {
    queryFn: getBuildTiers,
    placeholderData: [],
    staleTime: Infinity
  })
}
export const BuildTierSelectInput = ({
  name,
  control,
  errors,
  required,
  selectedOptionColor = 'resolveOranger',
  placeholder = 'Build Tier',
  isClearable = true,
  ...restProps
}) => {
  const buildTiers = useGetBuildTiers()
  return (
    <FormControl
      isRequired={required}
      isInvalid={!!errors[name]}
      {...restProps}
    >
        <FormLabel>Laptop Setup Tier</FormLabel>
      <ControlledSelect
        name={name}
        control={control}
        options={buildTiers.data}
        isLoading={buildTiers.isLoading}
        rules={{ required }}
        isClearable={isClearable}
        placeholder={placeholder}
        selectedOptionColor={selectedOptionColor}
      />
    </FormControl>
  )
}
