import { useFormContext, Controller, useWatch } from 'react-hook-form';
import {
    FormControl,
    FormLabel,
    Checkbox,
    FormHelperText,
    Textarea,
} from '@chakra-ui/react';
export const NewUserInput = ({ name, required = false }) => {
    const {
        control,
        register,
        formState: { errors },
    } = useFormContext();

    const newUser = useWatch({ name: 'new_user' });

    return (
        <>
            <FormControl mt="1rem" isInvalid={!!errors['new_user_details']}>
                <Controller
                    control={control}
                    name="new_user"
                    render={({ field: { value, ref, ...restField } }) => (
                        <Checkbox
                            isChecked={value}
                            colorScheme="resolveOranger"
                            ref={ref}
                            {...restField}
                        >
                            New User
                        </Checkbox>
                    )}
                />
            </FormControl>
            {newUser && (
                <FormControl
                    isRequired={true}
                    isInvalid={!!errors['new_user_details']}
                >
                    <FormLabel mb={0} mt="1rem">Licenses & Services</FormLabel>
                    <FormHelperText mt="0">
                        List the Licenses/Services that should be included in
                        the setup
                    </FormHelperText>
                    <Textarea
                        {...register('new_user_details', {
                            required: true,
                        })}
                        id="new_user_details"
                    />
                </FormControl>
            )}
        </>
    );
};
