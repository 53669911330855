import { Button, ButtonGroup } from '@chakra-ui/react';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { ExistingAutotaskTicket } from './ExistingAutotaskTicket';
import { NewAutotaskTicket } from './NewAutotaskTicket';

export const AutotaskTicket = () => {

    const autotaskNumber = useWatch({ name: 'autotask_number' });
    const clientId = useWatch("client_id");
    
    const [ticketType, setTicketType] = useState(autotaskNumber ? 'existing' : null);

    if (autotaskNumber) {
        return <ExistingAutotaskTicket />;
    }

    if (!ticketType) {
        return (
            <ButtonGroup mt="2rem" spacing={4}>
                <Button
                    colorScheme="resolveOranger"
                    variant="outline"
                    onClick={() => setTicketType('new')}
                >
                    New Ticket
                </Button>
                <Button
                    colorScheme="resolveBluer"
                    variant="outline"
                    onClick={() => setTicketType('existing')}
                >
                    Existing Ticket
                </Button>
            </ButtonGroup>
        );
    }

    if (ticketType === 'new') {
        return <NewAutotaskTicket clientId={clientId} />;
    }

    return <ExistingAutotaskTicket />;
};
