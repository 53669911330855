import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Header } from './components/Header.js';
import { OrderStepper } from './components/OrderStepper.js';
import { Routes, Route, useParams } from 'react-router';
import { BillingDetailsForm } from './Billing/index.js';
import { OrderDetailsForm } from './Details/index.js';
import { AutotaskTicket } from './Ticket/index.js';
import { AutotaskCost } from './Cost/index.js';
import { StepperActions } from './components/StepperActions.js';
import { FormProvider } from 'react-hook-form';
import { useOrderForm } from './hooks/useOrderForm';
import { Loading } from 'components/Loading.js';

export const OrderContext = React.createContext();

export const Ordered = () => {
    const { itemId } = useParams();
    const [isCompleted, setIsCompleted] = React.useState(false);
    const [currentStepSave, setCurrentStepSave] = React.useState(null);
    
    const { methods, isLoading } = useOrderForm(itemId);

    const contextValue = React.useMemo(() => ({
        setStepSave: setCurrentStepSave
    }), []);

    if(isLoading) return <Loading />

    return (
        <FormProvider {...methods}>
            <OrderContext.Provider value={contextValue}>
                <Flex justify="center" mt="2rem">
                    <Flex direction="column" width="80%">
                        <Header />
                        <OrderStepper 
                            isCompleted={isCompleted} 
                            setIsCompleted={setIsCompleted} 
                        />
                        <Routes>
                            <Route path="billing" element={<BillingDetailsForm />} />
                            <Route path="details" element={<OrderDetailsForm />} />
                            <Route path="ticket" element={<AutotaskTicket />} />
                            <Route 
                                path="costs" 
                                element={<AutotaskCost isCompleted={isCompleted} />} 
                            />
                        </Routes>

                        <StepperActions 
                            isCompleted={isCompleted} 
                            setIsCompleted={setIsCompleted} 
                            handleSave={currentStepSave}
                        />
                    </Flex>
                </Flex>
            </OrderContext.Provider>
        </FormProvider>
    );
};
