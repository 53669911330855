import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useFormContext, useWatch } from 'react-hook-form';

import { useCreateCost } from 'features/Quotes/api/createCost';
import { useGetAutotaskCosts } from 'features/Quotes/api/getTicketCosts';
import { useResolveToast } from 'hooks/useResolveToast';
import { useClearItemCharge } from '../../../../api/clearItemCharge';
import { useGetQuoteItemCharge } from '../api/getQuoteItemCharge';

import { StepsCompleted } from '../components/StepsComplete';
import { NoCostId } from './NoCostId';
import { CostsLoading } from './CostsLoading';
import { NoMatchingCosts } from './NoMatchingCosts';
import { Costs } from './Costs';
export const AutotaskCost = ({ isCompleted }) => {

    const { itemId } = useParams();
    const { toastFail } = useResolveToast();

    const orderForm = useFormContext()
    const ticketNumber = useWatch({ name: 'autotask_number' });
    const billingCodeId = useWatch({ name: 'at_billing_code_id' });
    const purchaseOrderNumber = useWatch({ name: 'client_pon' });

    const { data: costId } = useGetQuoteItemCharge({
        itemId,
        onSuccess: data => orderForm.setValue('at_cost_id', data)
    });

    const costs = useGetAutotaskCosts({ ticketNumber });
    const createCost = useCreateCost();
    const clearItemCharge = useClearItemCharge();

    const itemCost = useMemo(() => {
        return costs.data.find(c => c.id === costId);
    }, [costId, costs]);

    const handleAddCost = async () => {
        if (!billingCodeId) {
            toastFail('Must select billing code before adding costs');
            return;
        }

        const costData = {
            at_billing_code_id: billingCodeId.value,
            purchaseOrderNumber,
            ticketNumber,
            itemId,
        };
        await createCost.mutateAsync({ cost: costData });
    };

    const handleClearCharge = async () => {
        await clearItemCharge.mutateAsync({ itemId });
        orderForm.setValue('at_cost_id', null);
    };

    if(isCompleted) {
        return (
            <StepsCompleted />
        )
    }

    if (costs.isLoading || costs.isFetching) {
        return <CostsLoading />
    }

    if (!costId) {
        return <NoCostId isLoading={costs.isFetching || costs.isLoading} handleAddCost={handleAddCost} />
    }

    if (costId && !itemCost) {
        return <NoMatchingCosts isLoading={createCost.isLoading} handleClearCharge={handleClearCharge} />
    }

    return <Costs costs={costs.data} />
};
